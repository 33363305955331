// Font Family
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@700&family=IBM+Plex+Sans:wght@400;700&display=swap");
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
//Color variables
$getzenprimary: #11303d;
$getzenprimary-hover: #0e2631;
$getzenprimary-visited: #11303d;
$getzensecondary: #b68e63;
$getzensecondary-hover: #c5a582;
$getzensecondary-visited: #b68e63;
$getzenoutline: #ffffff;
$getzenoutline-hover: #edecea;
$getzenoutline-visited: #edecea;
$getzenoutline-border: #cdc7bf;
//Btn styles
$btn-border-radius: 0.313rem;
$btn-border-radius-sm: 0.313rem;
$btn-border-radius-lg: 0.313rem;
$btn-font-weight: 700;
$font-size-base: 1.125rem;
$h1-font-size: 3.375rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.875rem;
$h4-font-size: 1rem;
//import the functions & Variables
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
$getzen-theme-colors: (
  "getzenprimary": $getzenprimary,
  "getzensecondary": $getzensecondary,
);
$theme-colors: map-merge($getzen-theme-colors, $theme-colors);
//Import Boostrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

body {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  background-color: #fafafa;
  font-size: 16px;
}

.btn {
  min-width: 120px;

  &.action-btn-md {
    min-width: auto;
  }
}

.fa-1x {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cormorant", serif;
  font-weight: 700;
}

.btn-primary {
  color: white;

  &:hover {
    background-color: $getzenprimary-hover;
  }

  &:visited {
    background-color: $getzenprimary-visited;
  }

  &:active {
    background-color: $getzenprimary;
  }
}

.btn-secondary {
  color: black;

  &:hover {
    background-color: $getzensecondary-hover;
  }

  &:visited {
    background-color: $getzensecondary-visited;
  }

  &:active {
    background-color: $getzensecondary;
  }
}

.btn-outline {
  color: black;
  border: 1px solid $getzenoutline-border;

  &:hover {
    background-color: $getzenoutline-hover;
  }

  &:visited {
    background-color: $getzenoutline-visited;
  }

  &:active {
    background-color: $getzenoutline;
  }
}

.profile {
  height: 50px;
  width: 50px;
}

.name-topbar {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #11303d;
}

.logout-btn {
  font-family: "IBM Plex Sans", sans-serif;
}

.card-box {
  position: absolute;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 24px;
  width: 391px;
  height: 474px;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rmsc {
  &.disabled {
    .dropdown-container {
      background-color: #e9ecef;
    }
  }

  .dropdown-container {
    .dropdown-heading {
      height: 33px;
      padding: 0 0.5rem;

      .gray {
        stroke: #595e63;
      }
    }

    .dropdown-content {
      z-index: 9999;
    }
  }
}

.spn-c-p {
  cursor: pointer;
}

.form-control[readonly] {
  &.pop-input {
    background-color: #ffffff;
  }
}

.ant-picker {
  &.form-control-sm {
    border-radius: 0.2rem;
    border: 1px solid #ced4da;
    padding-left: 0.5rem;
    font-size: 0.98438rem;

    &.ant-picker-disabled {
      background-color: #e9ecef;
    }
  }
}

.fc {
  &.fc-media-screen {
    .fc-today-button {
      text-transform: capitalize;
    }

    .fc-toolbar-title {
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 400;
    }

    .fc-h-event {
      background-color: #11303d;
    }

    .fc-button-group {
      .fc-button {
        display: flex;
      }
    }
  }
}

.fw-medium-bold {
  font-weight: 600 !important;
}

.fa-1sm {
  font-size: 18px;
}

.box-border {
  border: 1px solid #f3f3f3;
}

.t-u {
  text-decoration: underline;
  text-underline-position: under;
}

.sc-dmRaPn {
  max-height: 100% !important;
}

.select-search {
  .select-search__value {
    input {
      min-height: calc(1.5em + 0.5rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 14px;
      border-radius: 0.2rem;
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      color: hwb(0deg 0% 100% / 85%);
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-image: escape-svg($form-select-indicator);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;

      &::placeholder {
        color: #aaa;
      }

      &:-ms-input-placeholder {
        color: #aaa;
      }

      &::-ms-input-placeholder {
        color: #aaa;
      }
    }
  }
  .select-search__select {
    border: 1px solid #000;
    background-color: #fff;

    .select-search__options {
      list-style: none;
      padding: 0px;
      margin: 0px;

      .select-search__row {
        .select-search__option {
          text-align: left;
          width: 100%;
          border: none;
          background-color: #fff;
        }
      }
    }
  }
}

.error {
  color: rgb(241, 85, 85);
}

.info-alert_box {
  position: fixed;
  top: 100px;
  right: 50%;
  transform: translateX(50%);
  z-index: 999;

  &.doc {
    top: 200px;
  }
}

.globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  z-index: 9999;

  .g-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loading-icon {
      width: 50px;
      height: 50px;
    }
  }
}

.dp-down-n {
  position: absolute;
  top: 85px !important;
  right: 27px !important;
}

.dp-down {
  position: absolute;
  top: 85px;
  right: 60px;
  background-color: #fff;
  width: 100%;
  max-width: 320px;
}

.css-b62m3t-container {
  .css-1s2u09g-control {
    min-height: 33.63px;
    align-content: center;
    padding: 0px 8px;
    .css-319lph-ValueContainer {
      padding: 0px 0px;
      .css-qc6sy-singleValue {
        font-size: 0.98438rem;
        color: #000;
      }
    }

    .css-1hb7zxy-IndicatorsContainer {
      .css-tlfecz-indicatorContainer {
        padding: 5px;
      }
    }

    .css-g1d714-ValueContainer {
      padding: 0px;

      .css-1rhbuit-multiValue {
        background-color: transparent;

        .css-12jo7m5 {
          padding-left: 0px;
          font-size: 14px;
        }
      }
    }
  }

  .css-26l3qy-menu {
    z-index: 9999;
  }
}

.authentication_input {
  display: inline-block;
}

.authentication_button {
  display: inline-block !important;
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  width: 191px;
  height: 46px;
  background: url(../../public/btn_google_signin_dark_normal_web.png);
  border: none;
}

.ql-container {
  &.ql-snow {
    .ql-editor {
      min-height: 90px;
    }
  }
}

.table-mdf {
  font-size: 16px;

  .thead-mdf {
    .tr-mdf {
      .th-mdf {
        border-color: #dee2e6 !important;
        border-top: 1px solid;
        border-bottom: 3px solid;
        border-left: 2px solid;
        border-right: 2px solid;
      }
      &:first-child {
        .th-mdf {
          border-left: 1px solid;
        }
      }

      &:last-child {
        .th-mdf {
          border-right: 1px solid;
        }
      }
    }
  }
  .tb-mdf {
    &:not(:first-child) {
      border-top: none;
    }
    .tr-mdf {
      .td-mdf {
        border-color: #dee2e6 !important;
        border-top: 3px solid;
        border-bottom: 3px solid;
        border-left: 2px solid;
        border-right: 2px solid;

        &:first-child {
          border-left: 1px solid;
        }

        &:last-child {
          border-right: 1px solid;
        }
      }
    }

    &:last-child {
      .tr-mdf {
        .td-mdf {
          border-bottom: 1px solid;
        }
      }
    }
  }
}

.disabled-pgn {
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.page-item {
  .page-link {
    &.active {
      background-color: #11303d;
      color: #ffffff !important;
      border: 1px solid #11303d;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    &:disabled {
      background-color: #f5f5f5;
    }
  }
}

.page-link {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.css-1insrsq-control {
  background-color: #e9ecef !important;
  border-color: #ced4da !important;

  .css-lr9fot-singleValue {
    color: #212529;
  }
}

.rm-scroll {
  overflow: hidden; /* Hide scrollbars */
}

.form-control {
  &[readonly] {
    &:focus {
      background-color: #e9ecef;
      border-color: #ced4da;
      outline: 0;
      box-shadow: none;
    }
  }
}

.form-control {
  &[readonly] {
    &:focus {
      &.pop-input {
        background-color: #ffffff;
        border-color: #ced4da;
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

.form-control {
  &:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
  }
}

.table-height-rtd {
  max-height: unset !important;
}

.react-select-container {
  .react-select__control {
    min-height: 33.63px;
    align-content: center;
    padding: 0px 8px;

    .react-select__value-container {
      padding: 0px;

      .react-select__multi-value {
        background-color: transparent;

        .react-select__multi-value__label {
          padding-left: 0px;
          font-size: 14px;
        }
      }
    }

    .react-select__indicators {
      .react-select__indicator {
        padding: 5px;
      }
    }
  }

  .react-select__menu {
    z-index: 999;
  }
}

.ql-picker-options {
  z-index: 9999 !important;
}

.paractitioner-document-info-text-color {
  color: #1890ff;
}

.remove-number-webkit-appearance {
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media only screen and (max-width: 992px) {
  .user-icon-panel {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .dp-down-n {
    top: 360px !important;
    left: 0px !important;
    margin-left: 0.5rem;
    right: unset !important;
  }
}
